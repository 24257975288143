import ContentArea from "../../shared/content/ContentArea";
import DeliveriesTable from "./DeliveriesTable";

const Deliveries = () => {
    return (
        <ContentArea title="Повідомлення">
            <DeliveriesTable />
        </ContentArea>
    )
}

export default Deliveries;
