import {useMutation} from "@tanstack/react-query";
import {AuthService} from "../services/auth.service";

export const useRefresh = () => {
    const {mutateAsync, data, error} = useMutation({
            mutationFn: (data) => AuthService.refreshAccessToken(data),
        })

    return {mutate: mutateAsync, data: data?.data, error}
}
