import TicketsTable from "../../../shared/tickets/TicketsTable";
import ContentArea from "../../../shared/content/ContentArea";

const TicketsAll = () => {
    return <>
        <ContentArea title="Звернення">
            <TicketsTable/>
        </ContentArea>
    </>
}
export default TicketsAll;
