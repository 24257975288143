import {useMutation, useQueryClient} from "@tanstack/react-query";
import {TicketsService} from "../services/tickets.service";


export const useCreateTicketMessage = (id) => {
    const queryClient = useQueryClient()
    const {mutate} =
        useMutation(
            {
                mutationKey:['create message'],
                mutationFn: data => TicketsService.createMessage(id, data),
                onSuccess: () => queryClient.invalidateQueries({ queryKey: ['ticket messages'] })
            })
    return {mutate}
}
