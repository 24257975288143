import {List, message} from "antd";
import React from "react";
import {usePatchChatButton} from "../../../hooks/usePatchChatButton";
import ListItem from "./ListItem";
import {usePathChatConstants} from "../../../hooks/usePathChatConstants";


const SettingLabels = ({keyName, data, isTextArea, isLoading, refetch}) => {
    const [messageApi, contextHolder] = message.useMessage();
    const {mutateAsync: mutateButton} = usePatchChatButton()
    const {mutateAsync: mutateTextConstant} = usePathChatConstants()
    return (<>
        {contextHolder}
        <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item, index) => (
                <ListItem id={item.id}
                          action={item.action}
                          markupName={item.markupName}
                          defVal={item.value}
                          refetch={refetch}
                          isTextArea={isTextArea}
                          messageApi={messageApi}
                          mutate={isTextArea ? mutateTextConstant : mutateButton}
                />
            )}
        />

    </>)
}

export default SettingLabels;
