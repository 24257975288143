import {useQuery} from "@tanstack/react-query";
import {DeliveriesService} from "../services/deliveries.service";

export const useDeliveries = (page, pageSize) => {
    const {isLoading, data, refetch} =
        useQuery(['deliveries list'], () => DeliveriesService.getAll(page, pageSize), {
            enabled: !!page
        })
    return {isLoading, deliveries: data?.data?.results, next: data?.data?.next, previous: data?.data?.previous, total: data?.data?.count, refetch}
}
