import {Drawer} from "antd";
import SubscriberInfo from "./SubscriberInfo";

const SubscriberInfoDrawer = ({status, changeStatus, data}) => {
    return (
        <Drawer
            title={`Профіль користувача`}
            placement="right"
            size="small"
            onClose={() => {
                changeStatus(false)
            }}
            open={status}
            // extra={
            //     <Space>
            //         <Button>Cancel</Button>
            //         <Button type="primary">
            //             OK
            //         </Button>
            //     </Space>
            // }
        >
            <SubscriberInfo data={data}/>
        </Drawer>
    );
}

export default SubscriberInfoDrawer;
