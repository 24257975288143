import MainLayout from "./componets/layout/Layout";
import {Route, Routes} from "react-router-dom";
import {ConfigProvider} from "antd";
import Dashboard from "./componets/screens/dashboard/Dashboard";
import Subscribers from "./componets/screens/subscribers/Subscribers";
import ChatUsersTelegram from "./componets/screens/chatusers/telegram/ChatUsersTelegram";
import ChatUsersViber from "./componets/screens/chatusers/viber/ChatUsersViber";
import TicketsConnection from "./componets/screens/tickets/connections/TicketsConnetions";
import TicketDetail from "./componets/screens/tickets/detail/TicketDetail";
import TicketsAll from "./componets/screens/tickets/all/TicketsAll";
import SettingsBotButton from "./componets/screens/settings/bot-buttons/SettingsBotButtons";
import SettingBotConstants from "./componets/screens/settings/bot-contstants/SettingBotConstants";
import SettingAdmins from "./componets/screens/settings/admins/SettingsAdmins";
import AdminCreateOrUpdate from "./componets/screens/settings/admins/SettingsAdminsCreateOrUpdate";
import Login from "./componets/screens/login/Login";
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import Deliveries from "./componets/screens/deliveries/Deliveries";
import {AuthProvider} from "./providers/auth.provider";

const styleApp = {
    overflow: 'hidden',
}

const queryClient = new QueryClient({
    logger: {
        log: (...args) => {
            // Log debugging information
        },
        warn: (...args) => {
            // Log warning
        },
        error: (...args) => {
            // Log error
        },
    },
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        },

    },

})

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <ConfigProvider
                theme={{
                    token: {
                        fontFamily: '\'Nunito\', sans-serif;',
                        colorBgBase: '#f0f0f0',
                        colorBgContainer: '#ffffff',
                    },
                }}
            >
                <AuthProvider>
                    <div style={styleApp}>
                        <Routes>
                            <Route path="/" element={
                                <MainLayout><Dashboard/></MainLayout>
                            }
                            />
                            <Route path="/login" element={
                                <Login/>
                            }/>
                            <Route path="/subscribers" element={
                                <MainLayout><Subscribers/></MainLayout>
                            }
                            />
                            <Route path="/subscribers/telegram" element={
                                <MainLayout><ChatUsersTelegram/></MainLayout>
                            }
                            />
                            <Route path="/subscribers/viber" element={
                                <MainLayout><ChatUsersViber/></MainLayout>
                            }
                            />
                            <Route path="/tickets/:ticketId" element={
                                <MainLayout><TicketDetail/></MainLayout>
                            }
                            />
                            <Route path="/tickets" element={
                                <MainLayout><TicketsAll/></MainLayout>
                            }
                            />
                            <Route path="/tickets/connections" element={
                                <MainLayout><TicketsConnection/></MainLayout>
                            }
                            />
                            <Route path="/settings/menu-buttons" element={
                                <MainLayout><SettingsBotButton/></MainLayout>
                            }
                            />
                            <Route path="/settings/text-messages" element={
                                <MainLayout><SettingBotConstants/></MainLayout>
                            }
                            />
                            <Route path="/settings/administrators" element={
                                <MainLayout><SettingAdmins/></MainLayout>
                            }
                            />
                            <Route path="/settings/administrators/:adminId" element={
                                <MainLayout><AdminCreateOrUpdate/></MainLayout>
                            }
                            />
                            <Route path="/settings/administrators/add" element={
                                <MainLayout><AdminCreateOrUpdate/></MainLayout>
                            }
                            />
                            <Route path="/deliveries" element={
                                <MainLayout><Deliveries/></MainLayout>
                            }/>
                        </Routes>

                    </div>
                </AuthProvider>
            </ConfigProvider>
        </QueryClientProvider>
    );
}

export default App;
