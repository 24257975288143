import {Button, Col, Form, Input, message, Row} from "antd";
import {useNavigate} from "react-router";
import {useLogin} from "../../../hooks/useLogin";
import {useEffect} from "react";
import {useAuthStore} from "../../../stores/auth.store";


const Login = () => {
    const navigate = useNavigate();
    const {mutateAsync, error, data} = useLogin()
    const [messageApi, contextHolder] = message.useMessage();
    const {setAccessToken, setRefreshToken} = useAuthStore(store => ({
        setAccessToken: store.setAccessToken,
        setRefreshToken: store.setRefreshToken
    }))
    const onFinish = async (values) => {
            await mutateAsync(values)
    };
    //
    useEffect(() => {
        if(error)
        {
            messageApi.open({
                type: 'error',
                content: 'Не знайдено користувача',
            });
        }

    }, [error])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(()=> {
        if (data) {
            setAccessToken(data.access)
            setRefreshToken(data.refresh)
            navigate('/')
        }
    }, [data, setAccessToken, setRefreshToken])

    return (
        <>
            {contextHolder}
            <Row
                style={{
                    backgroundImage: "url(/img/bg.jpg)",
                    height: '100vh',
                }}
                justify={"center"}
                align={"middle"}>

                <Col span={20} md={12} lg={5}>
                    <Row style={{
                        background: 'white',
                        padding: '0px 20px',
                        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                        borderRadius: '10px'
                    }}>
                        <Col span={24}>
                            <p align={"center"} style={{fontSize: '30px', fontWeight: '800'}}>
                                Вітаємо у системі ChatBot
                            </p>
                        </Col>
                        <Col span={24}>
                            <Form
                                name="basic"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Поле не може бути пустим!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Введіть логін"/>
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Поле не може бути пустим!',
                                        },
                                    ]}
                                >
                                    <Input.Password placeholder="Введіть пароль"/>
                                </Form.Item>


                                <Form.Item
                                    wrapperCol={{
                                        align: 'center'
                                    }}
                                >
                                    <Button style={{width: '100%'}} type="primary" htmlType="submit">
                                        Увійти
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>

                    </Row>
                </Col>


            </Row>
        </>

    )
}

export default Login;
