import {Col, Input, Row} from "antd";
import ContentArea from "../../shared/content/ContentArea";
import SubscribersTable from "./SubscribersTable";
import {useSubscribers, useSubscribersByPhone} from "../../../hooks/useSubscribers";
import {useEffect, useState} from "react";

const {Search} = Input;
const Subscribers = () => {
    const [subscribersStorage, setSubscribers] = useState([])
    const [phoneNumber, setPhoneNumber] = useState('')

    const {isLoading, subscribers: allSubscribers} = useSubscribers()
    const {subscribers: searchData, refetch} = useSubscribersByPhone(phoneNumber)

    const filterSubscribers = async (data) => {
        await refetch()
    }

    useEffect(() => {
        setSubscribers(allSubscribers)
    }, [allSubscribers])

    useEffect(() => {
        if (searchData) setSubscribers(searchData)
    }, [searchData])

    return (
        <ContentArea
            title={
                <Row align={"middle"}>
                    <Col span={16}>
                        <h2>Підписники системи</h2>
                    </Col>
                    <Col span={8}>
                        <Search
                            placeholder="Введіть номер телефону"
                            allowClear
                            onChange={(event) => {
                                setPhoneNumber(event.target.value);
                            }}
                            onSearch={filterSubscribers}
                            style={{
                                width: '100%',
                                verticalAlign: 'middle'
                            }}
                        />
                    </Col>
                </Row>
            }
        >
            <SubscribersTable data={subscribersStorage} isLoading={isLoading}/>
        </ContentArea>
    )
}

export default Subscribers;
