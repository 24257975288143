import {Col, Row} from "antd";

const SubscriberInfoItem = ({title, content}) => (
    <Row style={styles.sectionWrapper}>
        <Col span={12} style={styles.sectionTitle}>
            {title}
        </Col>
        <Col span={12} style={styles.sectionContent}>
            {content}
        </Col>
    </Row>
);

const styles = {
    sectionName: {
        display: 'block',
        marginBottom: '16px',
        fontSize: '14px',
        lineHeight: 1.5715
    },
    sectionWrapper: {
        margin: '5px 0px'
    },
    sectionTitle: {
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: 1.5715
    },
    sectionContent: {
        lineHeight: 1.5715
    }
}
export default SubscriberInfoItem;
