import api from "./api.service";




export const ChatUsersService = {
    async getBySystem(system) {
        return await api.get(`/chat-users?system=${system}`)
    },
}
