import {Space, Table, Tag} from "antd";
import React from "react";
import {InfoCircleOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router";
import moment from "moment/moment";

const SettingsAdminsTable = ({data, isLoading}) => {
    const navigate = useNavigate();

    const columns = [{
        title: "#",
        width: '20px',
        dataIndex: 'id'
    }, {
        title: 'Логін',
        dataIndex: 'username'
    }, {
        title: 'Імʼя',
        dataIndex: 'first_name'
    }, {
        title: 'Прізвище',
        dataIndex: 'last_name'
    }, {
        title: 'Дата створення',
        dataIndex: 'date_joined',
        align: 'center',
        render: (d) => {
            return moment(d).format("DD.MM.YY");
        }
    }, {
        title: 'Остання авторизація',
        dataIndex: 'last_login',
        align: 'center',
        render: (d) => {
            return d ? moment(d).format("DD.MM.YY - HH:mm:ss") : '-'
        }
    }, {
        title: 'Статус',
        align: 'center',
        dataIndex: 'is_active',
        render: (status) => {
            return status ? (
                <Tag color="green-inverse" key={status}>
                    Активний
                </Tag>
            ) : (
                <Tag color="red-inverse" key={status}>
                    Заблокований
                </Tag>
            )
        },
    }, {
        title: 'Ролі',
        align: 'center',
        render: (record) => {
            return (
                <Space>
                    <Tag color="green">
                        Оператор
                    </Tag>
                    {record.is_staff ?
                        <Tag color="orange">
                            Керівник
                        </Tag> : ''}
                    {record.is_superuser ?
                        <Tag color="red">
                            Адмін
                        </Tag> : ''}
                </Space>
            )
        }
    }, {
        title: '',
        align: 'center',
        render: (item) => <InfoCircleOutlined onClick={() => navigate(`/settings/administrators/${item.id}`)}/>,
    }]
    return (
        <>
            <Table columns={columns}
                   dataSource={data}
                   size="small"
                   loading={isLoading}
                   rowKey='id'
                   style={{width: '100%'}}/>
        </>
    )
}

export default SettingsAdminsTable;
