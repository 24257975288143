import api from "./api.service";

export const AuthService = {
    async login(data) {
        return  await api.post(`/token`, data)
    },
    async refreshAccessToken(refresh) {
        return await api.post('/token/refresh', {refresh})
    }
}



