import React from 'react';
import {Avatar, Table, Tag} from 'antd';
import {UserOutlined} from "@ant-design/icons";

const columns = [
    {
        title: '',
        dataIndex: 'avatar',
        width: '10px',
        align: 'center',
        render: (item) => (
            <Avatar size="small" icon={
                item ? <img
                    src={item}
                    alt={"none"}
                /> : <UserOutlined />
            }/>
        )
    },
    {
        title: 'Імʼя',
        dataIndex: 'name',
        sorter: {
            compare: (a, b) => a.name < b.name,
            multiple: 1,
        },
    },
    {
        title: 'Чат ID',
        dataIndex: 'chat_id',
        sorter: {
            compare: (a, b) => a.chat_id > b.chat_id,
            multiple: 1,
        },
    },
    {
        title: 'Статус',
        align: 'center',
        dataIndex: 'disable',
        render: (disable) => {
            return disable ? (
                <Tag color="red" key={disable}>
                    Заблокований
                </Tag>
            ) : (
                <Tag color="green" key={disable}>
                    Активний
                </Tag>
            )
        },
        sorter: {
            compare: (a, b) => a.disable < b.disable,
            multiple: 1,
        },
    },
];

const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
};
const ChatUsersTable = ({data, isLoading}) => {
    return (
        <Table columns={columns}
               dataSource={data}
               rowKey={'id'}
               loading={isLoading}
               size="small"
               onChange={onChange} style={{width: '100%'}}/>
    );
}
export default ChatUsersTable;
