import ContentArea from "../../../shared/content/ContentArea";
import ChatUsersTable from "../../../shared/chatusers/ChatUsersTable";
import {useChatUsers} from "../../../../hooks/useChatUsers";

const data = [
    {
        id: '1',
        name: 'Ostap))',
        chat_id: '0w9xHO5z4IO+7wkk7KOcWg==',
        disable: false,
    },
];
const ChatUsersViber = () => {
    const {isLoading, data} = useChatUsers('VIBER')
    return (
        <ContentArea
            title="Користувачі Viber">
            <ChatUsersTable data={data} isLoading={isLoading}/>
        </ContentArea>
    )
}
export default ChatUsersViber;
