import {useMutation} from "@tanstack/react-query";
import {SettingsService} from "../services/settings.service";

export const usePatchChatButton = () => {
    const {mutateAsync} =
        useMutation(['patch text constant'], (data => SettingsService.patchButton(data)),
            {
                enable: false
            })
    return {mutateAsync}
}
