import {useQuery} from "@tanstack/react-query";
import {SubscriberService} from "../services/subscriber.service";

export const useSubscribers = () => {
    const {isLoading, data} =
        useQuery(['subscribers list'], () => SubscriberService.getAll())
    return {isLoading, subscribers: data?.data?.results}
}

export const useSubscribersById = (id) => {
    const {
        isLoading,
        data,
    } = useQuery(['subscribers list', id],
        () => SubscriberService.getById(id),
        {
            enabled: !!id
        })
    return {isLoading, subscribers: data?.data?.results}
}
export const useSubscribersByPhone = (phoneNumber) => {
    // const {isLoading, data, mutateAsync} =
    //     useMutation(['subscribers list', phone_number],
    //         (phone_number) => SubscriberService.getByPhone(phone_number))
    const {
        isLoading,
        data,
        refetch
    } = useQuery(['subscribers list', phoneNumber],
        () => SubscriberService.getByPhone(phoneNumber),
        {
            enabled: false
        })
    return {isLoading, subscribers: data?.data?.results, refetch}
}
