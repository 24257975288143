import ContentArea from "../../../shared/content/ContentArea";
import TicketsTable from "../../../shared/tickets/TicketsTable";

const TicketsConnection = () => {
    return <>
        <ContentArea title="Запити на підключення">
            <TicketsTable />
        </ContentArea>
    </>
}
export default TicketsConnection;
