import React, {useState} from 'react';

import {Layout, theme} from 'antd';
import LayoutSidebar from "./LayoutSider";
import LayoutHeader from "./LayoutHeader";


const MainLayout = ({children}) => {
    const [collapsed, setCollapsed] = useState(false);
    const {token: {colorBgContainer}} = theme.useToken();
    return (
        <Layout>
            <LayoutSidebar collapsed={collapsed}/>
            <Layout>
                <LayoutHeader colorBgContainer={colorBgContainer} collapsed={collapsed} setCollapsed={setCollapsed}/>
                {children}
            </Layout>
        </Layout>
    );
}
export default MainLayout;
