import {useParams} from "react-router";
import ContentArea from "../../../shared/content/ContentArea";
import FormCreateOrUpdate from "./forms/FormCreateOrUpdate";

const AdminCreateOrUpdate = () => {
    const params = useParams();

    if (params.adminId) {
        return (
            <ContentArea title="Редагування користувача" span={16}>
                <FormCreateOrUpdate adminId={params.adminId} isCreate={false}/>
            </ContentArea>
        )
    }

    return (
        <ContentArea title="Створення користувача">
            <FormCreateOrUpdate isCreate={true} />
        </ContentArea>
    )
}

export default AdminCreateOrUpdate;
