import {useQuery} from "@tanstack/react-query";
import {SettingsService} from "../services/settings.service";

export const useChatConstants = () => {
    const {
        isLoading,
        data,
        refetch
    } = useQuery(
        {
            queryFn: () => SettingsService.getAllTextConstants(),
            queryKey: ['chat constants'],
            select: (data) => data?.data?.results.map(r => {
                return {
                    id: r.id,
                    action: r.key,
                    value: r.ua_dsc
                }

            })
        }
    )
    return {isLoading, data, refetch}
}
