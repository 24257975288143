import api from "./api.service";

export const TicketsService = {
    async getAll() {
        return await api.get('/tickets')
    },
    async getById(id) {
        return await api.get(`/tickets/${id}`)
    },
    async getMessages(id) {
        return await api.get(`/tickets/${id}/messages`)
    },
    async createMessage(id, data) {
        return await api.post(`/tickets/${id}/message`, data)
    },
    async changeStatus(id, status) {
        return await api.patch(`/tickets/${id}`, {status})
    }
}
