import {useMutation} from "@tanstack/react-query";
import {UsersService} from "../services/users.service";

export const useCreateUser = () => {
    const {
        isLoading,
        mutateAsync
    } = useMutation(['create user'],
        (data) => UsersService.createUser(data),
        {
            enabled: false
        })
    return {isLoading, mutateAsync}
}
