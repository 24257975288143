import {useQuery} from "@tanstack/react-query";
import {UsersService} from "../services/users.service";

export const useUser = (id) => {
    const {
        isLoading,
        data,
    } = useQuery(['user'],
        () => UsersService.getById(id),
        {
            enabled: !!id
        })
    return {isLoading, data: data?.data}
}
