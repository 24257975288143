import {useMutation, useQueryClient} from "@tanstack/react-query";
import {TicketsService} from "../services/tickets.service";

export const useChangeTicketStatus = (id, status) => {
    const queryClient = useQueryClient()
    const {mutate} =
        useMutation({
            mutationKey: ['change ticket status'],
            mutationFn: () => TicketsService.changeStatus(id, status),
            onSuccess: () => queryClient.invalidateQueries({ queryKey: ['ticket messages'] })
        })
    return {mutate}
}
