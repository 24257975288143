import React from 'react';
import {Table, Tag} from 'antd';
import {useNavigate} from "react-router";
import {InfoCircleOutlined} from "@ant-design/icons";
import {useTickets} from "../../../hooks/useTickets";

const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
};
const TicketsTable = () => {
    const navigate = useNavigate();
    const {isLoading, tickets} = useTickets()
    const columns = [
        {
            title: '#',
            width: '10px',
            dataIndex: 'id',
        },
        {
            title: 'Номер телефону',
            dataIndex: ['subscriber', 'phone_number']
            // sorter: {
            //     compare: (a, b) => a.chinese - b.chinese,
            //     multiple: 3,
            // },
        },
        {
            title: 'Опис',
            dataIndex: 'description',
            // sorter: {
            //     compare: (a, b) => a.math - b.math,
            //     multiple: 2,
            // },
        },
        {
            title: 'Створено',
            dataIndex: 'created',
            align: 'center',
            render: (d) => {
                const date = new Date(d);
                return <>
                    {date.toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                    })}
                </>
            }
            // sorter: {
            //     compare: (a, b) => a.math - b.math,
            //     multiple: 2,
            // },
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            align: 'center',
            filters: [
                {
                    text: 'Створено запит',
                    value: 'CREATED',
                },
                {
                    text: 'Закрито',
                    value: 'CLOSED',
                },
            ],
            render: (status) => {
                return status === 'CREATED' ? (
                    <Tag color="green" key={status}>
                        Cтворений запит
                    </Tag>
                ) : (
                    <Tag color="red" key={status}>
                        Закритий
                    </Tag>
                )
            },
            onFilter: (value, record) => record.status.indexOf(value) === 0,
        },
        {
            title: 'Коментар',
            dataIndex: 'comment',
            align: 'center',
            // sorter: {
            //     compare: (a, b) => a.math - b.math,
            //     multiple: 2,
            // },
        },
        {
            dataIndex: 'id',
            align: 'center',
            render: (id) => <InfoCircleOutlined onClick={() => navigate(`/tickets/${id}`)}/>,
        }
    ];
    return <>
        <Table columns={columns}
               dataSource={tickets}
               size="small"
               rowKey={"id"}
               loading={isLoading}
               onChange={onChange} style={{width: '100%'}}/>
    </>;
}

export default TicketsTable;
