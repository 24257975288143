import {Layout, Menu, Row} from "antd";
import {
    DesktopOutlined,
    FileOutlined,
    PieChartOutlined,
    UserOutlined,
} from "@ant-design/icons";
import React from "react";
import {useNavigate} from "react-router";

const {Sider} = Layout

const menuStyle = {
    height: '100vh',
    fontWeight: 'bold'
}
const logoStyle = {
    height: '50px',
    color: 'white',
}

function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label
    };
}

const routes = {
    'sub1': '/',
    'sub7': '/deliveries',
    '1': '/subscribers',
    '2': '/subscribers/telegram',
    '3': '/subscribers/viber',
    '4': '/tickets/connections',
    '5': '/tickets',
    '6': '/settings/text-messages',
    '7': '/settings/menu-buttons',
    '8': '/settings/administrators',
    '9': '/login'
}

const items = [
    getItem('Дашбоард', 'sub1', <PieChartOutlined/>,),
    getItem('Користувачі', 'sub2', <DesktopOutlined/>, [
        getItem('Підписники', '1'),
        getItem('Telegram', '2'),
        getItem('Viber', '3'),
    ], '/users/telegram'),
    getItem('Тікети', 'sub3', <UserOutlined/>, [
        getItem('На підключення', '4'),
        // getItem('Загальні питання', '5'),
    ]),
    // getItem('Webchat', 'sub4'),
    // getItem('Звіти', 'sub5', <TeamOutlined/>),
    getItem('Налаштування', 'sub6', <FileOutlined/>, [
        getItem('Тексти', '6'),
        getItem('Кнопки меню', '7'),
        getItem('Адміністратори', '8'),
    ]),
    getItem('Розсилки', 'sub7', <FileOutlined/>),
    getItem('Вихід', '9', <FileOutlined/>),
];


const LayoutSidebar = ({collapsed}) => {
    const navigate = useNavigate();

    const logo = collapsed ?
        <span style={{
            fontSize: '40px',
            fontWeight: '800',
        }}>CB</span> :
        <span style={{
            fontSize: '28px',
            fontWeight: '800',
        }}>ChatBot</span>

    return (
        <Sider trigger={null} collapsible collapsed={collapsed}>
            <Row style={logoStyle} justify="center" align="middle">
                {logo}
            </Row>

            <Menu
                theme="dark"
                mode="inline"
                style={menuStyle}
                defaultSelectedKeys={['1']}
                items={items}
                onClick={(data) => {
                    if(data.key === '9') {
                        localStorage.removeItem('accessToken')
                        localStorage.removeItem('refreshToken')
                        localStorage.setItem('isAuthenticated', false)
                    }
                    navigate(routes[data.key]);
                }}
            />
        </Sider>
    )
}

export default LayoutSidebar;
