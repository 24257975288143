import ContentArea from "../../../shared/content/ContentArea";
import SettingLabels from "../../../shared/settings/SettingLabels";
import {useChatButtons} from "../../../../hooks/useChatButtons";

const SettingsBotButton = () => {
    const {data, isLoading, refetch} = useChatButtons()
    return (
        <ContentArea title="Налаштування меню бота">
            <SettingLabels
                data={data}
                isLoading={isLoading}
                refetch={refetch}
                isTextArea={false}
                 />
        </ContentArea>
    )
}

export default SettingsBotButton;
