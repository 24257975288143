import {useQuery} from "@tanstack/react-query";
import {TicketsService} from "../services/tickets.service";

export const useTickets = () => {
    const {isLoading, data} =
        useQuery(['tickets list'], () => TicketsService.getAll())
    return {isLoading, tickets: data?.data?.results}
}

export const useTicket = (id) => {
    const {isLoading, data} =
        useQuery(['ticket', id], () => TicketsService.getById(id))
    return {isLoading, ticket: data?.data}
}

export const useTicketMessages = (id) => {
    const {isLoading, data, refetch} =
        useQuery(['ticket messages', id], () => TicketsService.getMessages(id),
            {
                select: ({data}) =>
                    data.map(m =>
                        ({
                            color: m?.is_admin ? 'red' : 'green',
                            children: (
                                <>
                                    <p style={style.title}>
                                        {m?.is_admin ? `${m?.user?.first_name} ${m?.user?.last_name} (${m?.user?.username})`
                                            : `${m?.chat_user?.name} (${m?.chat_user?.username})`}

                                    </p>
                                    <div style={style.message}>
                                        <div style={{
                                            textAlign: 'start',
                                            fontWeight: '200',
                                            fontSize: '10px'
                                        }}>17/08/2023 - 20:23
                                        </div>
                                        <div>{m?.content}</div>
                                    </div>
                                </>
                            )
                        })
                    )
            })
    return {isLoading, messages: data, refetch}
}


const style = {
    title: {
        fontWeight: '800',
        color: '#041527'
    },
    message: {
        background: 'white',
        width: 'fit-content',
        padding: '10px',
        fontWeight: '700',
        borderRadius: '10px',
        border: '1px solid'
    }
}
