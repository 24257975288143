import {create} from "zustand";
import {persist} from "zustand/middleware";

export const useAuthStore = create(persist((set, get) => ({
    accessToken: "wsaddfds",
    refreshToken: "2334",
    isAuth: false,
    loading: false,
    error: null,
    setRefreshToken: (token) => {
        set({refreshToken: token})
    },
    setAccessToken: (token) => {
        set({accessToken: token})
    }
}), {
    name: 'auth-store'
}))
