import {useMutation, useQueryClient} from "@tanstack/react-query";
import {SettingsService} from "../services/settings.service";


export const useCreateTextConstant = () => {
    const queryClient = useQueryClient()
    const {mutate, isError} =
        useMutation(
            {
                mutationKey:['create text constant'],
                mutationFn: data => SettingsService.createTextConstant(data),
                onSuccess: () => queryClient.invalidateQueries({ queryKey: ['chat constants'] })
            })
    return {mutate, isError}
}
