import {useQuery} from "@tanstack/react-query";
import {ChatUsersService} from "../services/chatUsers.service";

export const useChatUsers = (system) => {
    const {
        isLoading,
        data,
    } = useQuery(['chat-users list', system],
        () => ChatUsersService.getBySystem(system),
        {
            enabled: true
        })
    return {isLoading, data: data?.data?.results}
}
