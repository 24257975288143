import React, {useState} from 'react';
import {Col, Row, Table, Tag} from 'antd';
import {InfoCircleOutlined} from "@ant-design/icons";
import SubscriberInfoDrawer from "../../shared/subscriber/SubscriberInfoDrawer";


const SubscribersTable = ({data, isLoading}) => {
    const columns = [
        {
            title: 'Номер телефону',
            align: 'center',
            width: '15%',
            dataIndex: 'phone_number',
            key: 'phone_number',
        },
        {
            title: 'Прізвище',
            dataIndex: 'first_name',
            key: 'first_name',
        },
        {
            title: 'Імʼя',
            dataIndex: 'last_name',
            key: 'last_name',
        },
        {
            title: 'Статус',
            dataIndex: 'blocked',
            align: 'center',
            key: 'blocked',
            render: (blocked) => {
                return blocked ? (
                    <Tag color="red" key={blocked}>
                        Заблокований
                    </Tag>
                ) : (
                    <Tag color="green" key={blocked}>
                        Активний
                    </Tag>
                )
            }
        },
        {
            align: 'center',
            render: (item) => <InfoCircleOutlined onClick={() => openDrawerWithInfo(item)}/>,
        },
    ];
    const [open, setOpen] = useState(false);
    const [subscriberInfo, setSubscriberInfo] = useState(null)
    const openDrawerWithInfo = (item) => {
        setSubscriberInfo(item)
        setOpen(true)
    }

    return <>
        <Table
            columns={columns}
            style={{width: '100%'}}
            size="small"
            loading={isLoading}
            rowKey={'id'}
            expandable={{
                expandedRowRender: (record) => (
                    <div style={{margin: 0}}>
                        <Row justify={'space-around'}>
                            {record.chat_users.map((user) => {
                                return <Col key={`detail-${user.system}-${record.id}`}>
                                    <div><b>Система:</b> {user.system}</div>
                                    <div><b>Chat ID:</b> {user.chat_id}</div>
                                    <div><b>Логін:</b> {user.name}</div>
                                    <div>{user.blocked}</div>
                                </Col>
                            })}

                        </Row>
                    </div>
                ),
                rowExpandable: (record) => record.name !== 'Not Expandable',
            }}
            dataSource={data}
        />
        <SubscriberInfoDrawer status={open} changeStatus={setOpen} data={subscriberInfo}/>
    </>
}
export default SubscribersTable;
