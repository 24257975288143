import {Header} from "antd/lib/layout/layout";
import {Avatar, Button, Row, Space} from "antd";
import {MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined} from "@ant-design/icons";
import React from "react";
import {useUserStore} from "../../stores/user.store";

const LayoutHeader = ({colorBgContainer, collapsed, setCollapsed}) => {
    const adminName = useUserStore(store => store.adminName)
    return (
        <Header style={{
            padding: 0,
            background: colorBgContainer,
        }}>
            <Row gutter={12} justify={'space-between'}>
                <Button
                    type="text"
                    icon={collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                        fontSize: '16px',
                        width: 64,
                        height: 64,
                    }}
                />
                <Space style={{marginRight: '20px'}}>
                    <Space wrap size={25}>
                        <Space>
                            <Avatar size="large" icon={<UserOutlined/>}/>
                            <div style={{fontSize: 16, fontWeight: 'bold'}}>
                                {adminName}
                            </div>
                        </Space>
                    </Space>
                </Space>
            </Row>
        </Header>
    )
}

export default LayoutHeader;
