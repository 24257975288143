import React, {useState} from "react";
import {Button, Col, Input, Row} from "antd";
import {RedoOutlined} from "@ant-design/icons";

const ListItem = ({id, action, defVal, refetch, mutate, messageApi, isTextArea, markupName}) => {

    const onSubmit = async () => {
        await mutate({id, title: data})
        messageApi.open({
            type: 'success',
            content: 'Інформацію оновлено',
        });
        await refetch()
    }

    const [data, setData] = useState(defVal)

    return (
        <Row align={'middle'} style={{margin: '15px 0px'}}>
            {!isTextArea ?
                <Col span={4}>
                    <div><b>{markupName}</b></div>
                </Col> : ''
            }
            <Col span={5}>
                <div>{action}</div>
            </Col>
            <Col span={!isTextArea ? 13 : 17}>
                {isTextArea ?
                    <Input.TextArea value={data} rows={2} onChange={(e) => {
                        setData(e.target.value)
                    }}
                    /> : <Input value={data} onChange={(e) => {
                        setData(e.target.value)
                    }}/>}

            </Col>
            <Col span={2} style={{alignItems: 'center'}}>
                <Row justify={'center'}>
                    <Button type="primary" style={{backgroundColor: 'green'}} onClick={onSubmit}>
                        <RedoOutlined/>
                    </Button>
                </Row>

            </Col>
        </Row>
    )
}
export default ListItem;
