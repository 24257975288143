import {useQuery} from "@tanstack/react-query";
import {StatisticService} from "../services/statistic.service";

export const useDashboardStatistic = () => {
    const {
        isLoading,
        data,
    } = useQuery({
        queryKey: ['dashboard-statistic'],
        queryFn: () => StatisticService.getMainStatistic()
    })

    return {isLoading, data: data?.data}
}
