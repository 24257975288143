import ContentArea from "../../../shared/content/ContentArea";
import SettingLabels from "../../../shared/settings/SettingLabels";
import {useChatConstants} from "../../../../hooks/useChatConstants";
import {Button, Col, Row} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import ModalFormCreate from "./ModalFormCreate";
import {useState} from "react";

const SettingBotConstants = () => {
    const {data, isLoading, refetch} = useChatConstants()
    const [modalOpen, setModalOpen] = useState(false)
    return (
        <ContentArea title={
            <Col span={24}>
                <Row align={'middle'} justify={'space-between'}>
                    <h2>Тексти повідомлень</h2>
                    <Button type='primary' onClick={() => setModalOpen(true)}
                    ><PlusOutlined/></Button>
                </Row>
            </Col>
        }>
            <ModalFormCreate isOpen={modalOpen} change={setModalOpen}/>
            <SettingLabels
                data={data}
                refetch={refetch}
                isTextArea={true}
                isLoading={isLoading}
                keyName={'key'}/>
        </ContentArea>
    )
}

export default SettingBotConstants
