import ContentArea from "../../../shared/content/ContentArea";
import ChatUsersTable from "../../../shared/chatusers/ChatUsersTable";
import {useChatUsers} from "../../../../hooks/useChatUsers";

const ChatUsersTelegram = () => {
    const {isLoading, data} = useChatUsers('TELEGRAM')
    return (
        <ContentArea title="Користувачі Telegram">
            <ChatUsersTable data={data} isLoading={isLoading}/>
        </ContentArea>
    )
}


export default ChatUsersTelegram
