import {Button, Input, Form, Row, Col, Upload, Checkbox, Select} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useUpdateUser} from "../../../../../hooks/useUpdateUser";
import {useUser} from "../../../../../hooks/useUser";
import {useNavigate} from "react-router";
import {useCreateUser} from "../../../../../hooks/useCreateUser";

const FormCreateOrUpdate = ({adminId, isCreate}) => {
    const {data} = useUser(adminId)
    const navigate = useNavigate()
    const {mutateAsync} = useUpdateUser()
    const {mutateAsync: createUser} = useCreateUser()
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const onFinish = async (data) => {
        if (adminId) {
            await mutateAsync({
                id: adminId, data: {
                    ...data,
                    is_staff: data['checkbox-group'].includes('is_staff'),
                    is_superuser: data['checkbox-group'].includes('is_superuser')
                }
            })
            navigate('/settings/administrators?status=success')
        } else {
            await createUser({
                ...data,
                is_staff: data['checkbox-group'].includes('is_staff'),
                is_superuser: data['checkbox-group'].includes('is_superuser')
            })
            navigate('/settings/administrators?status=created')
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    if ((!isCreate && adminId && data) || (isCreate && !adminId)) {
        return (
            <Form
                name="basic"
                layout={"vertical"}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                initialValues={data ? {
                    ...data,
                    'checkbox-group': [data?.is_staff ? 'is_staff' : null, data?.is_superuser ? 'is_superuser' : null]
                } : {}}
            >
                <Row gutter={[40, 24]}>
                    <Col span={12}>
                        <Form.Item
                            label="Логін"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Пароль"
                            name="password"
                            rules={[
                                {
                                    required: isCreate,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>

                        <Row>
                            <Col span={8}>
                                <Form.Item label="Аватар" valuePropName="fileList" name="avatar"
                                           getValueFromEvent={normFile}>
                                    <Upload listType="picture-card">
                                        {/*action="/upload.do"*/}
                                        <div>
                                            <PlusOutlined/>
                                            <div
                                                style={{
                                                    marginTop: 8,
                                                }}
                                            >
                                                Upload
                                            </div>
                                        </div>
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Form.Item
                                    name="is_active"
                                    label="Статус"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your country!',
                                        },
                                    ]}
                                >
                                    <Select>
                                        <Select.Option value={true}>Активний</Select.Option>
                                        <Select.Option value={false}>Вимкнений</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Імʼя"
                            name="first_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Прізвище"
                            name="last_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            name="checkbox-group"
                            label="Ролі"
                        >
                            <Checkbox.Group
                                options={[{
                                    label: 'Адміністратор',
                                    name: 'is_superuser',
                                    value: 'is_superuser',
                                },
                                    {
                                        label: 'Керівник',
                                        name: 'is_staff',
                                        value: 'is_staff',
                                    }]}
                                // defaultValue={['is_staff', 'is_superuser']}
                            >
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Col span={24}>
                    <Row justify={"end"} align={"bottom"}>
                        <Button type="primary" htmlType="submit">
                            Створити
                        </Button>
                    </Row>
                </Col>

            </Form>
        )
    }

}
export default FormCreateOrUpdate;
