import {Button, Col, Row} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router";
import ContentArea from "../../../shared/content/ContentArea";
import SettingsAdminsTable from "./SettingsAdminsTable";
import {useUsers} from "../../../../hooks/useUsers";

const SettingAdmins = () => {
    const navigate = useNavigate();
    const {data, isLoading} = useUsers()
    return (
        <ContentArea title={
            <Col span={24}>
                <Row align={'middle'} justify={'space-between'}>
                    <h2>Налаштування адміністраторів</h2>
                    <Button type='primary'
                            onClick={() => navigate('/settings/administrators/add')}><PlusOutlined/></Button>
                </Row>
            </Col>}>
            <SettingsAdminsTable data={data} isLoading={isLoading}/>
        </ContentArea>
    )
}

export default SettingAdmins;
