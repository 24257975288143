import {useQuery} from "@tanstack/react-query";
import {UsersService} from "../services/users.service";

export const useUsers = () => {
    const {
        isLoading,
        data,
    } = useQuery(['users list'],
        () => UsersService.getAll(),
        {
            enabled: true
        })
    return {isLoading, data: data?.data?.results}
}
