import {Avatar, Col, Divider, Row, Space, Tag} from "antd";
import {UserOutlined} from "@ant-design/icons";
import React from "react";
import SubscriberInfoItem from "./SubscriberInfoItem";

const SubscriberInfo = ({data}) => {
    return (
        <Row>
            <Col span={24}>
                <Row justify={'center'}>
                    <Avatar size={128} icon={
                        <UserOutlined/>
                    }/>
                </Row>
            </Col>
            <Col span={24} style={{marginTop: '30px'}}>
                <SubscriberInfoItem title="Прізвище" content={data.first_name || '-'}/>
                <SubscriberInfoItem title="Імʼя" content={data.last_name || '-'}/>
                <SubscriberInfoItem title="Номер телефону" content={data.phone_number || '-'}/>
                <SubscriberInfoItem title="Статус" content={'Активний'}/>

                <Divider orientation="center"><p style={styles.sectionName}>Особові рахунки</p></Divider>
                <Col span={24}>
                    {data.billing_accounts.length ? data.billing_accounts.map(acc => {
                        return (
                            <Space key={`ba-${acc.id}`}>
                                <Tag color="gold" key={acc.id}>
                                    {acc.login}
                                </Tag>
                            </Space>
                        )
                    }) : 'Немає привʼязаних особових рахунків'}
                </Col>

                <Divider orientation="center"><p style={styles.sectionName}>Чат системи</p></Divider>
                <Space direction="vertical">
                    {
                        data.chat_users.map(u => {
                            if (u.system === 'TELEGRAM') {
                                return (
                                    <Row align={"middle"} key={`acc-${u.username}-${u.name}`}>
                                        <img width={24} height={24} src={"/img/tg-icon.png"} alt=""/>
                                        <span style={{marginLeft: '10px'}}>{u.name} ({u.username})</span>
                                    </Row>
                                )
                            }
                            if (u.system === 'VIBER') {
                                return (
                                    <Row align={"middle"} key={`acc-${u.username}-${u.name}`}>
                                        <img width={24} height={24} src={"/img/viber-icon.png"} alt=""/>
                                        <span style={{marginLeft: '10px'}}>{u.name}</span>
                                    </Row>
                                )
                            }
                        })
                    }
                </Space>
                <Divider/>
            </Col>
        </Row>
    )
}

const styles = {
    sectionName: {
        display: 'block',
        marginBottom: '16px',
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: 1.5715
    },
    sectionWrapper: {
        margin: '5px 0px'
    },
    sectionTitle: {
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: 1.5715
    },
    sectionContent: {
        lineHeight: 1.5715
    }
}

export default SubscriberInfo;
