import {useParams} from "react-router";
import {Col, Row} from "antd";
import TicketMessages from "./TicketMessages";
import ContentArea from "../../../shared/content/ContentArea";
import SubscriberInfo from "../../../shared/subscriber/SubscriberInfo";
import React from "react";
import {useTicket} from "../../../../hooks/useTickets";

const TicketDetail = () => {
    const params = useParams();
    const {isLoading, ticket} = useTicket(params.ticketId)
    const isClosed = ticket?.status === "CLOSED"
    const ticketIcon = ticket?.chat_user?.system === "TELEGRAM" ? "/img/tg-icon.png" : "/img/viber-icon.png"
    return isLoading ? <></> : (
        <ContentArea
            title={
                <Row justify={'start'} align={'middle'}>
                    <span style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        marginRight: '10px'
                    }}>Звернення #{ticket?.id}</span>
                    <img width={28} height={28} src={ticketIcon} alt=""/>
                </Row>

            }
        >
            <Row>
                <Col span={19} style={{paddingRight: '20px'}}>
                    <TicketMessages ticketId={ticket?.id} isClosed={isClosed}/>
                </Col>
                <Col span={5} style={{paddingLeft: '5px'}}>
                    <Row justify={"center"}>
                        <SubscriberInfo data={ticket?.subscriber}/>
                    </Row>

                </Col>
            </Row>

        </ContentArea>
    )
}

export default TicketDetail
