import {Button, Col, Divider, Modal, Row, Space, Timeline} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import {useState} from "react";
import {useTicketMessages} from "../../../../hooks/useTickets";
import {useCreateTicketMessage} from "../../../../hooks/useCreateTicketMessage";
import {useChangeTicketStatus} from "../../../../hooks/useChangeTicketStatus";

const TicketMessages = ({ticketId, isClosed}) => {
    const [isCloseTicket, setCloseTicket] = useState(Boolean(isClosed))
    const [messageContent, setMessageContent] = useState('')
    const {mutate: mutateStatus} = useChangeTicketStatus(ticketId, "CLOSED")
    const {isLoading, messages, refetch} = useTicketMessages(ticketId)
    const {mutate} = useCreateTicketMessage(ticketId)
    const sendMessage =  () => {
        mutate({content: String(messageContent)})
        setMessageContent('')
    }
    const showConfirm = async () => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            cancelText: "Скасувати",
            okText: "Так",
            content: "Ви справді бажаєте закрити дане звернення?",
            onOk() {
                mutateStatus()
                setCloseTicket(true)
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }

    return (
        <div style={style.wrapper}>
            <Row>
                <Col span={24}>
                    {isLoading ? <>Loading</> : (
                        <Timeline
                            style={style.timeline}
                            items={messages}
                            reverse={true}
                        />
                    )}

                </Col>
            </Row>

            <Row style={{marginRight: '15px'}}>
                <Divider/>
                <Col span={24}>
                    <TextArea rows={4} allowClear disabled={isCloseTicket} value={messageContent}
                              onChange={(event) => setMessageContent(event.target.value)}/>
                </Col>
                <Col span={24} style={{marginTop: '10px'}}>
                    <Row justify={'end'}>
                        <Space>
                            <Button danger onClick={showConfirm} disabled={isCloseTicket}>Закрити звернення</Button>
                            <Button type="primary" disabled={isCloseTicket} onClick={sendMessage}>Відправити</Button>
                        </Space>
                    </Row>

                </Col>
            </Row>
        </div>

    )
}
const style = {
    wrapper: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    timeline: {
        overflowY: 'scroll',
        height: '450px',
        paddingLeft: '5px',
        backgroundImage: "url('/img/bg_1.jpeg')"
    },
    title: {
        fontWeight: '800',
        color: '#041527'
    },
    message: {
        background: 'white',
        width: 'fit-content',
        padding: '10px',
        fontWeight: '700',
        borderRadius: '10px',
        border: '1px solid'
    }
}
export default TicketMessages;
