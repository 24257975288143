import {Card, Col, Row, Statistic} from "antd";
import React from "react";

const DashboardCounters = ({data}) => {
    return <Row gutter={[16, 24]} style={{width: '100%'}}>
        <Col span={6}>
            <Card bordered={false}>
                <Statistic
                    title="Користувачі Telegram"
                    value={data?.telegram_users}
                    precision={0}
                    valueStyle={{
                        color: '#3f8600',
                    }}
                />
            </Card>
        </Col>
        <Col span={6}>
            <Card bordered={false}>
                <Statistic
                    title="Користувачі Viber"
                    value={data?.viber_users}
                    precision={0}
                    valueStyle={{
                        color: '#3f8600',
                    }}
                />
            </Card>
        </Col>
        <Col span={6}>
            <Card bordered={false}>
                <Statistic
                    title="К-сть доданих аккаунтів"
                    value={data?.added_accounts}
                    precision={0}
                    valueStyle={{
                        color: '#3f8600',
                    }}
                />
            </Card>
        </Col>
        <Col span={6}>
            <Card bordered={false}>
                <Statistic
                    title="К-сть підписників"
                    value={data?.subscribers}
                    precision={0}
                    valueStyle={{
                        color: '#3f8600',
                    }}
                />
            </Card>
        </Col>

        <Col span={6}>
            <Card bordered={false}>
                <Statistic
                    title="Ліміт Viber"
                    value={0}
                    precision={0}
                    suffix="/ 10 000"
                    valueStyle={{
                        color: '#cf1322',
                    }}
                />
            </Card>
        </Col>
    </Row>
}
export default DashboardCounters;
