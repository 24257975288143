import api from "./api.service";

export const UsersService = {

    async getAll() {
        return await api.get('/users')
    },
    async getById(id) {
        return await api.get(`/users/${id}`)
    },
    async updateUser({id, data}) {
        return await api.put(`/users/${id}`, data)
    },
    async createUser(data) {
        return await api.post(`/users`, data)
    },
    async getInfo() {
        return await api.get('/users/info')
    }
}
