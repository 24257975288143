import api from "./api.service";




export const SubscriberService = {
    async getAll() {
        return await api.get('/subscribers')
    },
    async getByPhone(phoneNumber){
        return await api.get(`/subscribers?search=${phoneNumber}`)
    },
    async getById(id){
        return await api.get(`/subscribers?id=${id}`)
    }
}
