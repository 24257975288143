import {Row} from "antd";
import DashboardCounters from "./DashboardCounters";
import {useDashboardStatistic} from "../../../hooks/useDashboardStatistic";

const Dashboard = () => {
    const {data} = useDashboardStatistic()
    return <Row style={{margin: '20px 16px',}}>
        <DashboardCounters data={data}/>
    </Row>
}

export default Dashboard;
