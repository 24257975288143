import {useMutation} from "@tanstack/react-query";
import {UsersService} from "../services/users.service";

export const useUpdateUser = () => {
    const {
        isLoading,
        mutateAsync
    } = useMutation(['update user'],
        (data) => UsersService.updateUser(data),
        {
            enabled: false
        })
    return {isLoading, mutateAsync}
}
