import {Space, Table, Tag} from "antd";
import React, {useEffect, useState} from "react";
import {useDeliveries} from "../../../hooks/useDeliveries";
import moment from "moment";

const DeliveriesTable = () => {
    const columns = [
        {
            title: 'UUID',
            dataIndex: 'uuid',
            key: 'uuid',
        },
        {
            title: 'Тег',
            dataIndex: 'tag',
            key: 'tag',
        },
        {
            title: 'Системи відправки',
            dataIndex: 'system',
            align: 'center',
            key: 'system',
            render: (system) => {
                if (system === "MULTICHANNEL") return (<Space>
                    <Tag color="blue">
                        Telegram
                    </Tag>
                    <Tag color="purple">
                        VIBER
                    </Tag>
                </Space>)
                if (system === "TELEGRAM") return (<Tag color="blue">
                    Telegram
                </Tag>)
                if (system === "VIBER") return (<Tag color="purple">
                    VIBER
                </Tag>)

                else return system
            }
        },
        {
            title: 'Тип',
            dataIndex: 'type',
            align: 'center',
            key: 'type',
            render: (type) => {
                if (type === 'ADVERTISING') return (<b>Рекламне</b>)
                if (type === 'PAYMENT') return (<b>Нагадування про оплату</b>)
            }
        },
        {
            title: 'Дата',
            dataIndex: 'created',
            key: 'created',
            render: (d) => {
               return moment(d).format("DD.MM.YY - HH:mm:ss");
            }
        },
        {
            title: 'Зовнішня система',
            align: 'center',
            dataIndex: ['external_system', 'name'],
            key: ['external_system', 'name'],
        },
        {
            title: 'К-сть надісланих повідомлень',
            align: 'center',
            dataIndex: 'sent_messages',
            key: 'external_system',
            render: (messages) => messages.length
        },
    ]
    const nestedColumns = [
        {
            title: 'Чат ІД',
            dataIndex: 'chat_id',
            key: 'chat_id',
        },
        {
            title: 'Система',
            dataIndex: 'channel',
            key: 'channel',
            align: 'center',
            render: (system) => {
                if (system === "MULTICHANNEL") return (<Space>
                    <Tag color="blue">
                        Telegram
                    </Tag>
                    <Tag color="purple">
                        VIBER
                    </Tag>
                </Space>)
                if (system === "TELEGRAM") return (<Tag color="blue">
                    Telegram
                </Tag>)
                if (system === "VIBER") return (<Tag color="purple">
                    VIBER
                </Tag>)

                else return system
            }
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (status) => {
                if (status === "ERROR") return (
                    <Tag color="red">
                        Помилка доставки
                    </Tag>
                )
                if (status === "DELIVERED") return (
                    <Tag color="green">
                        Успішно доставленно
                    </Tag>
                )

                else return status
            }
        },
    ]
    const [tableParams, setTableParams] = useState({
        pagination: {
            total: 0,
            current: 1,
            pageSize: 10,
        },
    });
    const [data, setData] = useState(null)
    const {
        deliveries,
        isLoading,
        total,
        refetch
    } = useDeliveries(tableParams.pagination.current, tableParams.pagination.pageSize)

    useEffect(() => {
        setData(deliveries)
    }, [deliveries])
    //
    useEffect(() => {
        setData(deliveries)
        setTableParams({
            pagination: {
                ...tableParams.pagination,
                total: total || 0
            }
        })
    }, [total])

    const onChange = async (event) => {
        await setTableParams({pagination: event})
        await refetch()
    }
    return (
        <Table columns={columns}
               dataSource={data}
               rowKey={'id'}
               loading={isLoading}
               size="small"
               pagination={tableParams.pagination}
               expandable={{
                   expandedRowRender: (record) => {
                       return <Table columns={nestedColumns}
                                     dataSource={record?.sent_messages}
                                     rowKey={'uuid'}
                                     size={"small"}
                       />

                   }
               }}
               onChange={onChange} style={{width: '100%'}}/>
    );
}
export default DeliveriesTable;
