import {useAuthStore} from "../stores/auth.store";
import {useRefresh} from "../hooks/useRefresh";
import api from '../services/api.service'
import axios from "axios";
import instance from "../services/api.service";
import {useNavigate} from "react-router";
export const AuthProvider = ({children}) => {
    const {refreshToken, accessToken, setAccessToken} = useAuthStore(store => ({
        accessToken: store.accessToken,
        refreshToken: store.refreshToken,
        isAuth: store.isAuth,
        setAccessToken: store.setAccessToken
    }))
    const navigate = useNavigate()
    const {mutate, data} = useRefresh()

    api.interceptors.request.use(request => {
        if (!request.headers.Authorization) request.headers.Authorization = `Bearer ${accessToken}`
        return request
    })

    instance.interceptors.response.use(resp => resp,  async error => {
        const originalRequest = error.config;
        if (error.response.status === 403 && refreshToken) {
            try {
                const rawData = await fetch(`${process.env.REACT_APP_API_URL}/token/refresh`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({refresh: refreshToken})
                })
                const data = await rawData
                const content = await data.json();
                if(data?.status === 401) {
                    navigate('/login')
                }
                setAccessToken(content.access)
                axios.defaults.headers.common['Authorization'] = `Bearer ${content.access}`
                return axios(originalRequest)
            } catch (e) {
                navigate('/login')
            }
        }
        throw error
    })

    return (
        <>
            {children}
        </>
    )
}
