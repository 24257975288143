import {Button, Col, Form, Input, Modal, Row} from "antd";
import {createRef, useEffect} from "react";
import {useCreateTextConstant} from "../../../../hooks/useCreateTextConstant";

const ModalFormCreate = ({isOpen, change}) => {
    const {mutate, isError} = useCreateTextConstant()
    const refForm = createRef()

    useEffect(() => {
        if(isError) alert("Дублікат ключа")
    }, [isError])

    const onFinish = (values) => {
        mutate(values)
        change(false);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const handleOk = () => {
        refForm.current.submit()
    };
    const handleCancel = () => {
        change(false);
    };
    return <Modal title="Створити текст відповіді"
                  open={isOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={[
                      <Button key="back" onClick={handleCancel}>
                          Скасувати
                      </Button>,
                      <Button key="submit" type="primary" onClick={handleOk}>
                          Додати
                      </Button>
                  ]}>

        <Row align={'middle'} justify={'center'}>
            <Col span={24}>
                <Form
                    ref={refForm}
                    style={{marginTop: '10px'}}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"

                >
                    <Form.Item
                        label="Ключ"
                        name="key"
                        rules={[
                            {
                                required: true,
                                message: 'Будь ласка введіть ключ',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Текст"
                        name="ua_dsc"
                        rules={[
                            {
                                required: true,
                                message: 'Будь ласка введіть текст',
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                        label="Опис"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Будь ласка введіть опис',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                </Form>
            </Col>

        </Row>

    </Modal>
}

export default ModalFormCreate;
