import {useMutation} from "@tanstack/react-query";
import {SettingsService} from "../services/settings.service";

export const usePathChatConstants = () => {
    const {mutateAsync} =
        useMutation(['patch button'], (data => SettingsService.patchTextConstant(data)),
            {
                enable: false
            })
    return {mutateAsync}
}
