import React from "react";
import {Card, Col, Row} from "antd";

const ContentArea = ({title, children, span = 24}) => {
    return (
        <Row justify={"center"}>
            <Col span={span}>
                <Card
                    title={title}
                    style={styleSheet.area}
                    headStyle={{
                        fontWeight: 'bold',
                        fontSize: '22px',
                    }}
                >
                    <Col span={24}>
                        <Row>
                            <Col span={24}>
                                {children}
                            </Col>
                        </Row>
                    </Col>
                </Card>
            </Col>
        </Row>

    )
}

const styleSheet = ({
    area: {
        margin: '20px 16px',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        margin: '15px 0px'
    }
})
export default ContentArea;
