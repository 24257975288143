import api from "./api.service";

export const SettingsService = {
    async getAllButtons() {
        return await api.get(`/buttons`)
    },
    async getAllTextConstants() {
        return await api.get(`/text-constants`)
    },
    async createTextConstant (data) {
        return await api.post('/text-constants', data)
    },
    async patchButton({id, title}) {
        return await api.patch(`/buttons/${id}`, {title})
    },
    async patchTextConstant({id, title}) {
        return await api.patch(`/text-constants/${id}`, {ua_dsc: title})
    }
}
