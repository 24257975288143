import {create} from "zustand";
import {persist} from "zustand/middleware";

export const useUserStore = create(persist((set) => ({
        adminName: null,
        adminUsername: null,
        setAdminName: ({name}) => {
            set((state) => ({
                adminName: name
            }))
        },
    }),
    {
        name: 'user-store'
    }))
